











































import {Component, Prop, Vue} from 'vue-property-decorator';
import BaseDialog from "@/components/BaseDialog.vue";
@Component({
  name: 'dialog-mobile-user',
  components: {BaseDialog}
})
export default class DialogMobileUser extends Vue {
  @Prop() value!: boolean

  get show() {
    return this.value
  }

  onClick() {
    this.$emit('on-confirm', true)
  }
}
