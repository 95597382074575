import { render, staticRenderFns } from "./GetStarted.vue?vue&type=template&id=5c9e7cd8&scoped=true&"
import script from "./GetStarted.vue?vue&type=script&lang=ts&"
export * from "./GetStarted.vue?vue&type=script&lang=ts&"
import style0 from "./GetStarted.vue?vue&type=style&index=0&id=5c9e7cd8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c9e7cd8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';
installComponents(component, {VBtn,VImg,VRow,VSnackbar,VWindow,VWindowItem})
