















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
    name: 'toolbar-navigation-item'
})
export default class ToolbarNavigationItem extends Vue {
    @Prop() value!: string;
    @Prop() item!: {
        id: number,
        title: string,
        tag?: string,
        active: boolean,
        fulfilled?: boolean,
        last?: boolean
    };

    get selected() {
        return this.item.fulfilled || this.item.active;
    }

    onClick() {
        if (this.selected) {
            this.$emit('clicked', this.item.tag);
        }
    }
}
